import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

// Hooks, Querys & State
import { useAppState } from '../state/appState';
import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

// Components
import { StandardPageBanner } from '../components/blockZone/blocks';
import { PageMeta } from '../components/pageMeta';
import BlockZone from '~blockZone';

const PageTemplate = ({ data: { page } }) => {
  const { title, caption, theme, meta, pageBlocks } = page || {};

  const { setGlobalTheme } = useAppState();

  const pageTheme = theme.color;

  useEffect(() => {
    if (pageTheme) {
      setGlobalTheme(pageTheme);
    }
  }, [setGlobalTheme, pageTheme]);

  return (
    <>
      <StandardPageBanner caption={caption} title={title} />
      <PageMeta {...meta} />
      {pageBlocks && <BlockZone {...pageBlocks} />}
    </>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
      }
      theme {
        color
      }
      title
      caption
      pageBlocks {
        ...BlockZone
      }
    }
  }
`;
